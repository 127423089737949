import './../styles/App.css';
import React from 'react';
import { useState } from 'react';
import { getCurrentTimestamp, getRandomFromArray } from '../Utils';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const emptyString = "";

const itemNameColumnName = 'item';
const itemImageColumnName = 'image';

export default function EventMenu(props) {

    const [outcome, setOutcome] = useState("");

    const merge = () => {
        let outcomeString = getCurrentTimestamp() + "\n";

        // Give random item from merging items
        let possibleRewards = props.eventRewards.filter(item => item[itemNameColumnName] !== undefined && item[itemNameColumnName] !== emptyString);
        
        console.log("Possible event rewards: " + JSON.stringify(possibleRewards));

        let rewardItem = getRandomFromArray(possibleRewards);

        outcomeString = outcomeString + "Found " + rewardItem[itemNameColumnName] + "\n" + rewardItem[itemImageColumnName];

        setOutcome(outcomeString);
    };

    return(
    <div>
        <div>
            <h1 className="Pad-top-and-bottom-10px">Event</h1>
        </div>
        <div className="Activities-menu-content">
            <Button
                variant="success"
                size="lg"
                style={{width: "10%", minWidth:"100px", paddingBottom: "10px", marginTop: "10px"}}
                onClick={merge}>
                Go
            </Button>
            <h3 style={{paddingBottom: "10px", paddingTop: "20px"}}>Result</h3>
            <Form.Control 
                as="textarea"
                readOnly
                rows={16} 
                style={{width:"90%", maxWidth: "900px"}}
                value={outcome}/>
        </div>
    </div>)
}