import './../styles/App.css';
import React from 'react';
import { useState } from 'react';
import { getCurrentTimestamp, getRandomIntInclusive, getRandomFromArray } from '../Utils';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const emptyString = "";
const plaza = "Plaza";
const fields = "Fields";

const destinations = [
    { name: plaza },
    { name: fields }
  ];

const gears = "Gears";
const items = "Items";
const both = "Both";

const rewards = [
    { name: gears },
    { name: items },
    { name: both }
  ];

const critItemName = "CRIT";
const critColumnName = "crit";

const plazaColumnName = "Plaza";
const fieldsColumnName = "Fields";

export default function GatheringMenu(props) {

    const [destination, setDestination] = useState(plaza);

    const [rewardType, setRewardType] = useState(gears);

    const [numberOfRolls, setNumberOfRolls] = useState(1);

    const [outcome, setOutcome] = useState("");

    const onNumberOfRollsChanged = (event) => {
        setNumberOfRolls(event.target.value);
    }

    const goGathering = () => {

        let outcomeString = getCurrentTimestamp() + "\n";

        for (let i = 0; i < numberOfRolls; i++) {
            
            if (numberOfRolls > 1) {
                outcomeString += "Roll " + (i + 1) + "\n";
            }

            if(destination === plaza) {
    
                // Chance to get companion instead of what you were looking for
                var companionChance = props.odds.chanceToGetCompanionFromGatheringInPlaza;

                if (rewardType === both) {
                    // If used "oil", the chance for companion is increased. 
                    console.log("Used oil, so chance to get companion is multiplied by " + props.odds.usedOilWhileGatheringCompanionOddsMultiplier);
                    companionChance = companionChance * props.odds.usedOilWhileGatheringCompanionOddsMultiplier;
                }

                console.log("There is a " + props.odds.chanceToGetCompanionFromGatheringInPlaza + " chance to get a companion from gathering in the plaza.");
                
                let roll = Math.random();
                let foundCompanion = roll < parseFloat(props.odds.chanceToGetCompanionFromGatheringInPlaza);
                let rewardToReplace = "";


                if(foundCompanion) {
                    console.log("Rolled " + roll + ", got a companion.");
                    outcomeString = outcomeString + "Found a companion!\n";
    
                    if(rewardType === both) {
                        console.log("Companion will replace either the gear reward or the item reward (50/50 chance).");
                        
                        let roll2 = Math.random();
    
                        if(roll2 < 0.5) {
                            console.log("Rolled " + roll2 + ", companion replaces gear reward.");
                            rewardToReplace = gears;
                        }
                        else {
                            console.log("Rolled " + roll2 + ", companion replaces item reward.");
                            rewardToReplace = items;
                        }
                    }
                }
                else {
                    console.log("Rolled " + roll + ", did not get a companion.");
                }
    
                if((rewardType === gears && !foundCompanion) || (rewardType === both && rewardToReplace !== gears)) {
                    // Roll for gears
                    var numGears = getRandomIntInclusive(props.odds.minGearsFromGathering, props.odds.maxGearsFromGathering);
                    outcomeString = outcomeString + "Found " + numGears + " gears in the plaza.\n";
                }
                
                if((rewardType === items && !foundCompanion) || (rewardType === both && rewardToReplace !== items)) {
                    // Roll for items
                    let plazaItems = props.items.filter(item => item[plazaColumnName] !== undefined && item[plazaColumnName] !== emptyString);
    
                    let nonCritPlazaItems = plazaItems.filter(item => item[critColumnName] === undefined || item[critColumnName] === emptyString);
    
                    let selectedItem = getRandomFromArray(nonCritPlazaItems);
    
                    if(selectedItem.name === critItemName) {
    
                        outcomeString = outcomeString + "Rolled a crit!\n";
    
                        let critPlazaItems = plazaItems.filter(item => item[critColumnName] !== undefined && item[critColumnName] !== emptyString);
    
                        selectedItem = getRandomFromArray(critPlazaItems);
                    }
    
                    outcomeString = outcomeString + "Found " + selectedItem.name + " in the plaza.\n";
                }
            }
            else if(destination === fields) {

                console.log("Chance to fail gathering in fields: " + props.odds.chanceToFailGatheringInFields);

                let randomNum = Math.random();
    
                if(randomNum < parseFloat(props.odds.chanceToFailGatheringInFields)) {
    
                    console.log("Rolled " + randomNum + ", failed gathering in fields.");
                    outcomeString = outcomeString + "Didn't find anything in the fields. :(\n";
                }
                else {
    
                    console.log("Rolled " + randomNum + ", successfully gathered in fields.");

                    let rewardPool = [];
    
                    for(let i = 0; i < parseInt(props.odds.slotsToGetChildFromGathering); i++) {
                        rewardPool.push("Child");
                    }
    
                    for(let i = 0; i < parseInt(props.odds.slotsToGetItemFromGathering); i++) {
                        rewardPool.push("Item");
                    }
    
                    let reward = getRandomFromArray(rewardPool);
    
                    if(reward === "Child") {
                        outcomeString = outcomeString + "Found a child in the fields.\n";
                    }
                    else if(reward === "Item") {
                        outcomeString = outcomeString + "Found an item in the fields.\n";
    
                        let fieldsItems = props.items.filter(item => item[fieldsColumnName] !== undefined && item[fieldsColumnName] !== emptyString);
    
                        let nonCritFieldsItems = fieldsItems.filter(item => item[critColumnName] === undefined || item[critColumnName] === emptyString);

                        console.log("Fields items: " + JSON.stringify(fieldsItems));
    
                        let selectedItem = getRandomFromArray(nonCritFieldsItems);

                        if(selectedItem.name === critItemName) {
    
                            outcomeString = outcomeString + "Rolled a crit!\n";
        
                            let critFieldsItems = fieldsItems.filter(item => item[critColumnName] !== undefined && item[critColumnName] !== emptyString);
        
                            selectedItem = getRandomFromArray(critFieldsItems);
                        }
    
                        outcomeString = outcomeString + "The item was " + selectedItem.name + ".\n";
                    }
                }
            }
        }

        setOutcome(outcomeString);
    }

    return(
    <div>
        <div>
            <h1 className="Pad-top-and-bottom-10px">Gathering</h1>
        </div>
        <div className="Activities-menu-content">
            <Form style={{width: "90%"}}>
                <Row style={{justifyContent: "center"}}>
                    <Col sm="7" style={{justifyContent: "center"}}>
                        <InputGroup className="py-2" style={{justifyContent: "center"}}>
                            <InputGroup.Text id="destinationLabel">
                            Destination
                            </InputGroup.Text>
                            {destinations.map((dest, idx) => (
                                <ToggleButton
                                    key={idx}
                                    id={`destination-${idx}`}
                                    type="radio"
                                    size="md"
                                    variant={dest.name === destination ? 'success' : 'dark'}
                                    name="destination"
                                    value={dest.name}
                                    checked={dest.name === destination}
                                    onChange={(e) => setDestination(e.currentTarget.value)}
                                >
                                    {dest.name}
                                </ToggleButton>
                            ))}
                        </InputGroup>
                        <InputGroup className="py-2" style={{justifyContent: "center"}}>
                            <InputGroup.Text id="rewardLabel">
                            Reward
                            </InputGroup.Text>
                            {rewards.map((rew, idx) => (
                                <ToggleButton
                                    disabled={destination !== plaza}
                                    key={idx}
                                    id={`reward-${idx}`}
                                    type="radio"
                                    size="md"
                                    variant={destination !== plaza ? 'outline-secondary' : (rew.name === rewardType ? 'success' : 'dark')}
                                    name="reward"
                                    value={rew.name}
                                    checked={destination !== plaza ? false : (rew.name === rewardType)}
                                    onChange={(e) => setRewardType(e.currentTarget.value)}
                                >
                                    {rew.name}
                                </ToggleButton>
                            ))}
                        </InputGroup>
                        <InputGroup className="py-2" style={{justifyContent: "center"}}>
                            <InputGroup.Text id="numberOfRollsLabel">
                            Number of rolls
                            </InputGroup.Text>
                            <Form.Control
                                style={{maxWidth: "129px"}}
                                variant="dark"                  
                                type="number" 
                                min="1"
                                name="numberOfRolls"
                                value={numberOfRolls}
                                onChange={onNumberOfRollsChanged}/>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
            <Button
                variant="success"
                size="lg"
                style={{width: "10%", minWidth:"100px", paddingBottom: "10px", marginTop: "10px"}}
                onClick={goGathering}>
                Go
            </Button>
            <h3 style={{paddingBottom: "10px", paddingTop: "20px"}}>Result</h3>
            <Form.Control 
                as="textarea"
                readOnly
                rows={16} 
                style={{width:"90%", maxWidth: "900px"}}
                value={outcome}/>
        </div>
    </div>)
}