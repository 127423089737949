const DBConfig = {
    siteSpreadsheetId: '1-7B4NvpTrWPidg8DufexjNG750sva0lLVKUMtyQFwSs',
    publicSpreadsheetId: '1Qg7e4KP_MNCFwYba4pQqqTwO3vMmlkQ2Ez7dlRuEQrY',

    allDeerTraitsSheetName: 'Deer Traits',
    allCompanionTraitsSheetName: 'Companion Traits',
    listedDeerTraitsSheetName: 'Deer Listed Traits',
    listedCompanionTraitsSheetName: 'Companion Listed Traits',
    oddsSheetName: 'Odds',
    itemsSheetName: 'Items',
    eventRewardsSheetName: 'Event Explore',
    templatesSheetName: 'Templates',
    artistsSheetName: 'Artists',
    deerSheetName: 'Deer Info',
    companionsSheetName: 'Companion Info'
};

export default DBConfig;