import './../styles/App.css';
import React from 'react'
import { useState, useEffect } from 'react';
import ConfigMenu from "./ConfigMenu";
import BreedDeerMenu from "./BreedDeerMenu.js";
import BreedCompanionsMenu from "./BreedCompanionsMenu.js";
import BlindBagMenu from "./BlindBagMenu.js";
import TemplatesMenu from "./TemplatesMenu.js";
import RngMenu from "./RngMenu.js";
import DBConnector from "./DBConnector.js";
import 'react-tabs/style/react-tabs.css';
import adminDeer from '../images/adminDeer.png';
import mascotDeer from '../images/mascotDeer.png';
import MediaQuery from 'react-responsive';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import GatheringMenu from "./GatheringMenu.js";
import ErrandsMenu from "./ErrandsMenu.js";
import MergingMenu from "./MergingMenu.js";
import EventMenu from "./EventMenu.js";
import RaffleMenu from "./RaffleMenu.js";

function AdminPage() {

  useEffect(() => { document.body.style.backgroundColor = 'black' }, [])

  const breedDeer = "breed-deer";
  const breedCompanions = "breed-companions";
  const activityGathering = "activity-gathering";
  const activityErrands = "activity-errands";
  const activityMerging = "activity-merging";
  const activityEvent = "activity-event";
  const blindBags = "blind-bags";
  const raffle = "raffle";
  const templates = "templates";
  const rng = "rng";
  const settings = "settings";

  const defaultState = {
    odds: {
      chanceOfMutation: 0,
      chanceToPassOnCommonTrait: 0,
      chanceToPassOnUncommonTrait: 0,
      chanceToPassOnRareTrait: 0,
      slotsToGetWrongErrandReward: 0,
      blindBagMinimumValue: 0
    },
    allDeerTraits: [],
    listedDeerTraits: [],
    allCompanionTraits: [],
    listedCompanionTraits: [],
    items: [],
    eventRewards: [],
    templates: [],
    artists: [],
    deer: [],
    companions: []
  };

  const [state, setState] = useState(defaultState);
  const [selectedTab, setSelectedTab] = useState(breedDeer);

  const onAllDeerTraitsUpdated = (newAllDeerTraits) => {
    setState(values => ({...values, allDeerTraits: newAllDeerTraits}));
  }

  const onListedDeerTraitsUpdated = (newListedDeerTraits) => {
    setState(values => ({...values, listedDeerTraits: newListedDeerTraits}));
  }

  const onAllCompanionTraitsUpdated = (newCompanionTraits) => {
    setState(values => ({...values, allCompanionTraits: newCompanionTraits}));
  }

  const onListedCompanionTraitsUpdated = (newListedCompanionTraits) => {
    setState(values => ({...values, listedCompanionTraits: newListedCompanionTraits}));
  }

  const onOddsUpdated = (newOdds) => {
    setState(values => ({...values, odds: newOdds}));
  }

  const onOddsUpdatedFromConfigMenu = (oddsName, oddsValue) => {
    let newOdds = state.odds;
    newOdds[oddsName] = oddsValue;
    console.log("Changed odd " + oddsName + " to value " + oddsValue);
    setState(values => ({...values, odds: newOdds}));
  }

  const onItemsUpdated = (newItems) => {
    setState(values => ({...values, items: newItems}));
  }

  const onEventRewardsUpdated = (newEventRewards) => {
    setState(values => ({...values, eventRewards: newEventRewards}));
  }

  const onTemplatesUpdated = (newTemplates) => {
    setState(values => ({...values, templates: newTemplates}));
  }

  const onArtistsUpdated = (newArtists) => {
    setState(values => ({...values, artists: newArtists}));
  }

  const onDeerUpdated = (newDeer) => {
    setState(values => ({...values, deer: newDeer}));
  }

  const onCompanionsUpdated = (newCompanions) => {
    setState(values => ({...values, companions: newCompanions}));
  }

  return (
    <div className="Admin-page" data-bs-theme="dark">
      <DBConnector 
        onAllDeerTraitsUpdated={onAllDeerTraitsUpdated} 
        onListedDeerTraitsUpdated={onListedDeerTraitsUpdated}
        onAllCompanionTraitsUpdated={onAllCompanionTraitsUpdated}
        onListedCompanionTraitsUpdated={onListedCompanionTraitsUpdated}
        onOddsUpdated={onOddsUpdated}
        onItemsUpdated={onItemsUpdated}
        onEventRewardsUpdated={onEventRewardsUpdated}
        onTemplatesUpdated={onTemplatesUpdated}
        onArtistsUpdated={onArtistsUpdated}
        onDeerUpdated={onDeerUpdated}
        onCompanionsUpdated={onCompanionsUpdated}
      />
      <div className="Admin-page-header">
        <MediaQuery minWidth={880}>
          <img src={adminDeer} alt="A handsome admin deer and a cute cat" width="165" height="160"></img>
        </MediaQuery>
        <h1 style={{fontSize: "64px", paddingBottom: "30px"}}>Gear Deer Admin</h1>
        <MediaQuery minWidth={880}>
          <img src={mascotDeer} alt="Morgana the deer and her companion" width="165" height="161"></img>
        </MediaQuery>
      </div>
      <Container fluid="true" className="px-0 mx-0">
        <Navbar expand="sm" className="bg-body-tertiary">
          <Nav
            style={{width:"100%"}}
            className="justify-content-center"
            variant="tabs"
            defaultActiveKey={breedDeer}
            onSelect={setSelectedTab}>
            <Nav.Item>
              <Nav.Link eventKey={breedDeer}>Breed Deer</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={breedCompanions}>Breed Companions</Nav.Link>
            </Nav.Item>
            <NavDropdown title="Activities" id="activities-dropdown">
              <NavDropdown.Item eventKey={activityGathering}>Gathering</NavDropdown.Item>
              <NavDropdown.Item eventKey={activityErrands}>Errands</NavDropdown.Item>
              <NavDropdown.Item eventKey={activityMerging}>Merging</NavDropdown.Item>
              <NavDropdown.Item eventKey={activityEvent}>Event</NavDropdown.Item>
            </NavDropdown>
            <Nav.Item>
              <Nav.Link eventKey={blindBags}>Blind Bags</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              {/* <Nav.Link eventKey={raffle}>Raffle</Nav.Link> */}
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={templates}>Templates</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={rng}>RNG</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={settings}>Settings</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar>
      </Container>
      {
        selectedTab === breedDeer &&
        <BreedDeerMenu 
          odds={state.odds} 
          allDeerTraits={state.allDeerTraits} 
          listedDeerTraits={state.listedDeerTraits} 
          deer={state.deer}
          companions={state.companions}
        />
      }
      {
        selectedTab === breedCompanions &&
        <BreedCompanionsMenu 
          odds={state.odds} 
          allCompanionTraits={state.allCompanionTraits}
          listedCompanionTraits={state.listedCompanionTraits} 
          companions={state.companions}
        />
      }
      {
        selectedTab === activityGathering &&
        <GatheringMenu items={state.items} odds={state.odds}/>
      }
      {
        selectedTab === activityErrands &&
        <ErrandsMenu items={state.items} odds={state.odds}/>
      }
      {
        selectedTab === activityMerging &&
        <MergingMenu items={state.items} odds={state.odds}/>
      }
      {
        selectedTab === activityEvent &&
        <EventMenu eventRewards={state.eventRewards} odds={state.odds}/>
      }
      {
        selectedTab === blindBags &&
        <BlindBagMenu 
          items={state.items}
          odds={state.odds}
          eventRewards={state.eventRewards}
        />
      }
      {
        selectedTab === raffle &&
        <RaffleMenu/>
      }
      {
        selectedTab === templates &&
        <TemplatesMenu 
          templates={state.templates} 
          artists={state.artists}
        />
      }
      {
        selectedTab === rng &&
        <RngMenu/>
      }
      {
        selectedTab === settings &&
        <ConfigMenu 
          odds={state.odds} 
          onOddsUpdated={onOddsUpdatedFromConfigMenu}
          allDeerTraits={state.allDeerTraits}
          items={state.items}
        />
      }
      <div style={{display: "flex", justifyContent: "center"}}>
        <MediaQuery maxWidth={879}>
          <img src={adminDeer} alt="A handsome admin deer and a cute cat" width="165" height="161"></img>
        </MediaQuery>
      </div>
    </div>
  );
}

export default AdminPage;
