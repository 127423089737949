import './../styles/App.css';
import React from 'react';
import { useState } from 'react';
import { getCurrentTimestamp, getRandomIntInclusive, getRandomFromArray } from '../Utils';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const emptyString = "";

const errandsColumnName = "Errands";

const gears = "Gears";
const items = "Items";

const rewards = [
    { name: gears },
    { name: items }
  ];

const yes = "Yes";
const no = "No";

const hasMergedOptions = [
    { name: no },
    { name: yes }
];

const critItemName = "CRIT";
const critColumnName = "crit";

export default function ErrandsMenu(props) {

    const [rewardType, setRewardType] = useState(gears);

    const [hasMerged, setHasMerged] = useState(no);

    const [numberOfRolls, setNumberOfRolls] = useState(1);

    const [outcome, setOutcome] = useState("");

    const onNumberOfRollsChanged = (event) => {
        setNumberOfRolls(event.target.value);
    }

    const runErrand = () => {
        let outcomeString = getCurrentTimestamp() + "\n";

        for (let i = 0; i < numberOfRolls; i++) {

            if (numberOfRolls > 1) {
                outcomeString += "Roll " + (i + 1) + "\n";
            }

            let actualRewardType = rewardType;

            // If hasMerged isn't checked, there is a chance to get the wrong reward.
            if(hasMerged !== yes) {
                console.log("Has not merged, so there is a " + props.odds.chanceToGetWrongErrandReward + " chance to get the wrong reward.");
                if(Math.random() < parseFloat(props.odds.chanceToGetWrongErrandReward)) {
                    actualRewardType = rewardType === gears ? items : gears;
                    outcomeString = outcomeString + "Found " + actualRewardType + " instead.\n";
                }
            }
            else {
                console.log("Has merged, no chance of getting wrong reward.");
            }
    
            if(actualRewardType === gears) {
                
                const numGears = getRandomIntInclusive(props.odds.minGearsFromErrand, props.odds.maxGearsFromErrand);
                outcomeString = outcomeString + "Found " + numGears + " gears.\n";
            }
            else if(actualRewardType === items) {
                let errandItems = props.items.filter(item => item[errandsColumnName] !== undefined && item[errandsColumnName] !== emptyString);
    
                let nonCritItems = errandItems.filter(item => item[critColumnName] === undefined || item[critColumnName] === emptyString);
    
                let itemPool = [];
                nonCritItems.forEach(item => {
                    for(let i = 0; i < item.slots; i++) {
                        itemPool.push(item);
                    }
                });
    
                let selectedItem = getRandomFromArray(itemPool);
    
                // If hit a crit, then select from crit item pool
                if(selectedItem.name === critItemName) {
                    outcomeString = outcomeString + "Rolled a crit!\n";
    
                    let critItems = errandItems.filter(item => item[critColumnName] !== undefined && item[critColumnName] !== emptyString);
                 
                    let critItemPool = [];
                    critItems.forEach(item => {
                        for(let i = 0; i < item.slots; i++) {
                            critItemPool.push(item);
                        }
                    });
    
                    selectedItem = getRandomFromArray(critItemPool);
                }
    
                outcomeString = outcomeString + "Found " + selectedItem.name + " (cost: " + selectedItem.cost + ").\n";
            }
        }

        setOutcome(outcomeString);
    };

    return(
    <div>
        <div>
            <h1 className="Pad-top-and-bottom-10px">Errands</h1>
        </div>
        <div className="Activities-menu-content">
            <Form style={{width: "90%"}}>
                <Row style={{justifyContent: "center"}}>
                    <Col sm="7" style={{justifyContent: "center"}}>
                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                        <InputGroup.Text id="rewardLabel">
                        Reward
                        </InputGroup.Text>
                        {rewards.map((opt, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`reward-${idx}`}
                                type="radio"
                                size="md"
                                variant={opt.name === rewardType ? 'success' : 'dark'}
                                name="reward"
                                value={opt.name}
                                checked={opt.name === rewardType}
                                onChange={(e) => setRewardType(e.currentTarget.value)}
                            >
                            {opt.name}
                        </ToggleButton>
                        ))}
                    </InputGroup>
                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                        <InputGroup.Text id="hasMergedLabel">
                        Has merged?
                        </InputGroup.Text>
                        {hasMergedOptions.map((opt, idx) => (
                            <ToggleButton
                                key={idx}
                                id={`hasMerged-${idx}`}
                                type="radio"
                                size="md"
                                variant={opt.name === hasMerged ? 'success' : 'dark'}
                                name="hasMerged"
                                value={opt.name}
                                checked={opt.name === hasMerged}
                                onChange={(e) => setHasMerged(e.currentTarget.value)}
                            >
                                {opt.name}
                            </ToggleButton>
                        ))}
                    </InputGroup>
                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                        <InputGroup.Text id="numberOfRollsLabel">
                        Number of rolls
                        </InputGroup.Text>
                        <Form.Control
                            style={{maxWidth: "75px"}}
                            variant="dark"                  
                            type="number" 
                            min="1"
                            name="numberOfRolls"
                            value={numberOfRolls}
                            onChange={onNumberOfRollsChanged}/>
                    </InputGroup>
                    </Col>
                </Row>
            </Form>
            <Button
                variant="success"
                size="lg"
                style={{width: "10%", minWidth:"100px", paddingBottom: "10px", marginTop: "10px"}}
                onClick={runErrand}>
                Go
            </Button>
            <h3 style={{paddingBottom: "10px", paddingTop: "20px"}}>Result</h3>
            <Form.Control 
                as="textarea"
                readOnly
                rows={16} 
                style={{width:"90%", maxWidth: "900px"}}
                value={outcome}/>
        </div>
    </div>)
}