import './../styles/App.css';
import React from 'react';
import { useState } from 'react';
import { getCurrentTimestamp, getRandomIntInclusive, getRandomFromArray } from '../Utils';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const mergingColumnName = "Merging";
const emptyString = "";

export default function MergingMenu(props) {

    const [outcome, setOutcome] = useState("");

    const merge = () => {
        let outcomeString = getCurrentTimestamp() + "\n";

        // Give random gears, in increments of 5
        const gearIncrement = 5;
        let numGears = gearIncrement * getRandomIntInclusive(props.odds.minGearsFromMerging / gearIncrement, props.odds.maxGearsFromMerging / gearIncrement);

        outcomeString = outcomeString + "Received " + numGears + " gears.\n";

        // Give random item from merging items
        let possibleRewards = props.items.filter(item => item[mergingColumnName] !== undefined && item[mergingColumnName] !== emptyString);
        
        console.log("Possible merging items: " + JSON.stringify(possibleRewards));
        
        let itemsPool = [];

        possibleRewards.forEach(item => {
            for(let i = 0; i < parseInt(item["slots"]); i++) {
                itemsPool.push(item);
            }
        });

        let rewardItem = getRandomFromArray(itemsPool);

        outcomeString = outcomeString + "Received " + rewardItem.name + ".\n";

        setOutcome(outcomeString);
    };

    return(
    <div>
        <div>
            <h1 className="Pad-top-and-bottom-10px">Merging</h1>
        </div>
        <div className="Activities-menu-content">
            <Button
                variant="success"
                size="lg"
                style={{width: "10%", minWidth:"100px", paddingBottom: "10px", marginTop: "10px"}}
                onClick={merge}>
                Go
            </Button>
            <h3 style={{paddingBottom: "10px", paddingTop: "20px"}}>Result</h3>
            <Form.Control 
                as="textarea"
                readOnly
                rows={16} 
                style={{width:"90%", maxWidth: "900px"}}
                value={outcome}/>
        </div>
    </div>)
}