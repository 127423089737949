import React from 'react'
import { useEffect } from 'react';


function HomePage() {

    useEffect(() => {
        window.location.replace('https://www.chickensmoothie.com/Forum/viewtopic.php?f=58&t=4935495');
    });

  return (
    <div></div>
  );
}

export default HomePage;
