import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import Select from 'react-select'

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const FullWidthDiv = styled.div`
    width: 100%;
    padding: 10px 0px;
`;

const breedingTypeCompanion = "Companion";

export default function BreedingParent(props) {

    const speciesList = [{label: "Dog", value: "Dog"}, {label: "Cat", value: "Cat"}];

    const [selectedParent, setSelectedParent] = useState({label: "Custom", value: "Custom"});

    const [selectedSpecies, setSelectedSpecies] = useState({label: "Dog", value: "Dog"});

    const [speciesDropdownDisabled, setSpeciesDropdownDisabled] = useState(false);

    const [traitsInTable, setTraitsInTable] = useState([]);

    useEffect(() => {
        // When allTraits changes, copy the new traits into table
        setTraitsInTable(props.allTraits);
      }, [props.allTraits]);

    const columnsForExistingParent = [
        {field: 'id', flex: 1.5, suppressMovable: true},
        {field: 'name', flex: 4, suppressMovable: true},
        {field: 'category', flex: 2, suppressMovable: true},
        {field: 'rarity', flex: 2.5, suppressMovable: true}
    ];

    const columnsForCustomParent = [
        {field: 'active', flex: 1.5, checkboxSelection: true, suppressMovable: true},
        {field: 'id', flex: 1.5, suppressMovable: true},
        {field: 'name', flex: 4, suppressMovable: true},
        {field: 'category', flex: 2, suppressMovable: true},
        {field: 'rarity', flex: 2.5, suppressMovable: true}
    ];

    const [traitTableColumns, setTraitTableColumns] = useState(columnsForCustomParent);

    const defaultColDef = useMemo(() => { 
        return {
            sortable: true,
            filter: false,
            resizable: true,
            cellStyle: {textAlign: 'left'}
        };
    }, []);

    const onSelectedTraitsChanged = (e) => {
        if (selectedParent.label === "Custom") {

            let selectedTraits = e.api.getSelectedRows();

            props.onParentTraitsChanged(props.parentNumber, selectedTraits);
        }
        else {
            props.onParentTraitsChanged(props.parentNumber, traitsInTable);
        }
    }

    const onSelectedParentChanged = (e) => {
        setSelectedParent(e);

        if (e.label === "Custom") {
            setTraitTableColumns(columnsForCustomParent);
            setTraitsInTable(props.allTraits);

            if (props.breedingType === breedingTypeCompanion) {
                setSpeciesDropdownDisabled(false);
            }
        }
        else {
            const parentTraitIds = e.value.traitIds;
            const traits = props.allTraits.filter(trait => parentTraitIds.includes(trait.id));

            setTraitTableColumns(columnsForExistingParent);
            setTraitsInTable(traits);
            props.onParentTraitsChanged(props.parentNumber, traits);

            if (props.breedingType === breedingTypeCompanion) {
                handleSpeciesChange({label: e.value.species, value: e.value.species});
                setSpeciesDropdownDisabled(true);
            }
        }
    }

    const handleSpeciesChange = (e) => {
        setSelectedSpecies({label: e.label, value: e.label});
        props.onParentSpeciesChanged(props.parentNumber, e.label);
    }

    const parentList = () => {
        let list = [];

        list.push({label: "Custom", value: {}})

        props.possibleParents.forEach(parent => {
            let dropdownEntry = {};
            dropdownEntry.label = parent.id + " - " + parent.name;
            dropdownEntry.value = parent;
            list.push(dropdownEntry);
        });
        return list;
    };

    const dropdownTheme = (theme) => ({
        ...theme,
        borderRadius: 8,
        colors: {
        ...theme.colors,
          primary: '#68686e', // Selected option
          primary25: '#198754', // Hover over selection
          primary50: '#146c43', // Background of selection when mouse is down
          neutral0: '#212529', // Dropdown background
          neutral5: '#282c34', // Background when disabled
          neutral10: '#495057', // Outline when disabled
          neutral20: '#495057', // Outline when not selected
          neutral80: 'white', // Text of selection
        }
    });

    return (
        <div style={{
            backgroundColor: "#282c34",
            borderRadius: "4px",
            minWidth: "49%",
            display: "flex",
            justifyContent: "center"
        }}>
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems:"center",
                justifyContent: "center",
                margin: "10px"
            }}>
                <FullWidthDiv>
                    <label htmlFor="selectedParent">Parent {props.parentNumber + 1}</label>
                </FullWidthDiv>
                <div style={{width: "100%"}}>
                    <Select 
                        id="selectedParent"
                        options={parentList()}
                        onChange={onSelectedParentChanged}
                        theme={dropdownTheme}
                        placeholder="Custom"
                    />
                </div>

                { props.breedingType === breedingTypeCompanion &&
                    <FullWidthDiv>Species</FullWidthDiv>
                }
                { props.breedingType === breedingTypeCompanion &&    
                    <div style={{minWidth: "30%"}}>              
                        <Select 
                            id="parentSpecies"
                            options={speciesList}
                            onChange={handleSpeciesChange}
                            theme={dropdownTheme}
                            value={selectedSpecies}
                            isDisabled={speciesDropdownDisabled}
                        />
                    </div>
                }
                <FullWidthDiv>
                    Traits
                </FullWidthDiv>
                <FullWidthDiv>
                    <div className="ag-theme-alpine-dark" style={{width: "100%", height: 400}}>
                        <AgGridReact
                            rowData={traitsInTable}
                            columnDefs={traitTableColumns}
                            defaultColDef={defaultColDef}
                            animateRows={true}
                            rowSelection="multiple"
                            suppressRowClickSelection="true"
                            onSelectionChanged={onSelectedTraitsChanged}
                        />
                    </div>
                </FullWidthDiv>
            </div>
        </div>)
}