import './../styles/App.css';
import React from 'react';
import {useState} from 'react';
import {getRandomIntInclusive, getCurrentTimestamp} from '../Utils.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function RngMenu(props) {

    const [minimum, setMinimum] = useState(1);
    const [maximum, setMaximum] = useState(10);
    const [numRolls, setNumRolls] = useState(1);

    const [outcome, setOutcome] = useState("");

    const onMinimumChanged = (event) => {
        const value = event.target.value;
    
        setMinimum(value);
    }

    const onMaximumChanged = (event) => {
        const value = event.target.value;
    
        setMaximum(value);
    }

    const onNumRollsChanged = (event) => {
        const value = event.target.value;
    
        setNumRolls(value);
    }

    const roll = () => {
        let outcomeString = getCurrentTimestamp() + "\n\n";

        let minimumInt = Math.ceil(minimum);
        let maximumInt = Math.floor(maximum);

        if (minimumInt >= maximumInt) {
            outcomeString += "Minimum must be less than maximum";
        }
        else {
            for (let i = 0; i < numRolls; i++) {
                let randomNum = getRandomIntInclusive(minimumInt, maximumInt);
                outcomeString += randomNum + "\n";
            }
        }

        setOutcome(outcomeString);
    };

    return(
    <div>
        <div>
            <h1 className="Pad-top-and-bottom-10px">RNG</h1>
        </div>
        <Form>
            <Row fluid style={{justifyContent: "center"}}>
                <Col style={{justifyContent: "center"}}>
                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                        <InputGroup.Text id="minimumLabel">
                        Minimum
                        </InputGroup.Text>
                        <Form.Control
                            style={{maxWidth: "190px"}}
                            variant="dark"                   
                            type="number" 
                            min="0"
                            step="1"
                            name="minimum"
                            value={minimum}
                            onChange={onMinimumChanged}/>
                    </InputGroup>
                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                        <InputGroup.Text id="maximumLabel">
                        Maximum
                        </InputGroup.Text>
                        <Form.Control
                            style={{maxWidth: "190px"}}
                            variant="dark"                   
                            type="number" 
                            min="0"
                            step="1"
                            name="maximum"
                            value={maximum}
                            onChange={onMaximumChanged}/>
                    </InputGroup>
                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                        <InputGroup.Text id="numRollsLabel">
                        Number of rolls
                        </InputGroup.Text>
                        <Form.Control
                            style={{maxWidth: "150px"}}
                            variant="dark"                   
                            type="number" 
                            min="0"
                            step="1"
                            name="numRolls"
                            value={numRolls}
                            onChange={onNumRollsChanged}/>
                    </InputGroup>
                </Col>
            </Row>
        </Form>
        <div className="Rng-menu-content">
            <Button
                variant="success"
                size="lg"
                style={{width: "10%", minWidth:"100px", paddingBottom: "10px", marginTop: "10px"}}
                onClick={roll}>
                Go
            </Button>
            <h3 style={{paddingBottom: "10px", paddingTop: "20px"}}>Result</h3>
            <Form.Control 
                as="textarea"
                readOnly
                rows={10} 
                style={{width:"90%", maxWidth: "900px"}}
                value={outcome}/>
        </div>
    </div>)
}