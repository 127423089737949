import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import HomePage from "./components/HomePage.js";
import AdminPage from "./components/AdminPage.js";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage/>} />
          <Route exact path="/admin" element={<AdminPage/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
