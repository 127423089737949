import './../styles/App.css';
import React from 'react';
import {useState} from 'react';
import {getCurrentTimestamp, getRandomFromArray} from '../Utils.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import * as cheerio from 'cheerio';

export default function RaffleMenu(props) {

    const litterRaffleHeaderText = "I Want to Enter a Litter Raffle!";
    const numLinesInEntry = 5;

    const chickenSmoothieHostName = "chickensmoothie.com";
    const chickenSmoothieWwwHostName = "www.chickensmoothie.com";
    const proxyHostName = "gear-deer-proxy.azure-api.net/chickensmoothie";
    const proxyPath = "/chickensmoothie";

    const [pageUrl, setPageUrl] = useState("https://www.chickensmoothie.com/Forum/viewtopic.php?f=58&t=4935495&start=620");

    const [litterName, setLitterName] = useState("Litter Name");

    const [allEntriesLoaded, setAllEntriesLoaded] = useState(false);

    const [entries, setEntries] = useState([]);

    const [outcome, setOutcome] = useState("");

    const loadAllRaffleEntries = async () => {

        setAllEntriesLoaded(false);
        setEntries([]);
        setOutcome("Loading data from ChickenSmoothie, this may take a minute...");

        var proxyUrl = new URL(pageUrl);

        console.log("Hostname is " + proxyUrl.hostname);

        if (proxyUrl.hostname !== chickenSmoothieHostName &&
            proxyUrl.host !== chickenSmoothieWwwHostName) {
            
            setOutcome("Error: Can only pull data from " + chickenSmoothieHostName);
            return;
        }

        // Change URL to point to proxy.
        proxyUrl.hostname = proxyHostName;
        proxyUrl.pathname = proxyPath + proxyUrl.pathname;

        // Retrieve data for all posts in the thread. Each request only returns 10 posts so we have to make several calls.
        proxyUrl.searchParams.set('start', 0);

        var numPages = 1;

        // Retrieve first page.
        console.log("Retrieving page 1 of thread...");

        await fetch(proxyUrl)
        .then(response => response.text())
        .then(rawData =>
            {
                const $ = cheerio.load(rawData);

                // Parse to see how many pages are in the thread.
                // Should be in the format "Page 1 of x".
                var paginationText = $('div.pagination > a:contains("Page 1 of "):first').text();
                numPages = paginationText.split(" ").at(-1);

                parseEntriesFromResponse(rawData);
            })
        .catch(err =>
            {
                setOutcome(err);
                return;
            });

        // Retrieve the rest of the pages, if any.
        for (let i = 1; i < numPages; i++) {

            console.log("Retrieving page " + i + " of thread...");

            proxyUrl.searchParams.set('start', 10 * (i - 1));

            await fetch(proxyUrl)
            .then(response => response.text())
            .then(rawData =>
                {
                    parseEntriesFromResponse(rawData);
                })
            .catch(err =>
                {
                    setOutcome(err);
                    return;
                });
        }

        setOutcome("Loaded " + entries.length + " raffle entries from " + numPages + " pages.");
        setAllEntriesLoaded(true);
    };

    const parseEntriesFromResponse = (rawData) => {
        const $ = cheerio.load(rawData);

        // Get all posts that contain the desired text.
        $('div.content:contains(' + litterRaffleHeaderText + ')').each((postIndex, post) => {

            // Replace <br> tags with newlines.
            const postText = $(post).find("br").replaceWith("\n").end().text();

            const postLines = postText.split('\n');

            // Read through each line in the post looking for entries. A single post may contain multiple entries.
            for (let i = 0; i < postLines.length; i++) {

                if (postLines[i].includes(litterRaffleHeaderText) &&
                    postLines.length >= i + numLinesInEntry) {
                    
                    var entry = "";

                    for (let j = 0; j < 5; j++) {
                        entry += postLines[i + j] + "\n";
                    }

                    setEntries(entries.push(entry));
                }
            }
        });
    }

    const drawWinner = () => {
        var result = "Entries for litter " + litterName + ":\n";

        const entriesForLitter = entries.filter(e => e.includes(litterName));
        entriesForLitter.forEach(e => {
            result += e + "\n";
        });

        setOutcome(result);
    }

    const onLitterNameChanged = (event) => {
        setLitterName(event.target.value);
    }

    const onPageUrlChanged = (event) => {
        setPageUrl(event.target.value);
    }

    return(
    <div>
        <div>
            <h1 className="Pad-top-and-bottom-10px">Raffle</h1>
        </div>
        <div className="Raffle-menu-content">
            <Form>
                <InputGroup className="py-2" style={{maxWidth: "800px"}}>
                    <InputGroup.Text id="litterNameLabel">
                    Litter Name
                    </InputGroup.Text>
                    <Form.Control
                        variant="dark"                   
                        type="text"
                        name="litterName"
                        value={litterName}
                        onChange={onLitterNameChanged}/>
                </InputGroup>
                <InputGroup className="py-2" style={{minWidth: "800px"}}>
                    <InputGroup.Text id="pageUrlLabel">
                    Thread URL
                    </InputGroup.Text>
                    <Form.Control
                        variant="dark"                   
                        type="text" 
                        name="pageUrl"
                        value={pageUrl}
                        onChange={onPageUrlChanged}/>
                </InputGroup>
            </Form>
            <Button
                variant="success"
                size="lg"
                style={{width: "10%", minWidth:"100px", paddingBottom: "10px", marginTop: "30px"}}
                onClick={loadAllRaffleEntries}>
                Load Data
            </Button>
            <Button
                variant="success"
                size="lg"
                style={{width: "10%", minWidth:"100px", paddingBottom: "10px", marginTop: "30px"}}
                onClick={drawWinner}
                disabled={!allEntriesLoaded}>
                Draw Winner
            </Button>
            <h3 style={{paddingBottom: "10px", paddingTop: "20px"}}>Result</h3>
            <Form.Control 
                as="textarea"
                readOnly
                rows={20} 
                style={{width:"90%", maxWidth: "900px"}}
                value={outcome}/>
        </div>
    </div>)
}