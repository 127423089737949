import './../styles/App.css';
import React from 'react';
import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const FormTable = styled.table`
    border-spacing: 10px;
`;

const FormTableCell = styled.td`
`;

export default function ConfigMenu(props) {

    const [traitTableColumns] = useState([
        {field: 'id', flex: 1.5, suppressMovable: true},
        {field: 'name', flex: 3, suppressMovable: true},
        {field: 'category', flex: 2, suppressMovable: true},
        {field: 'rarity', flex: 2.5, suppressMovable: true}
      ]);

    const [itemTableColumns] = useState([
        {field: 'name', flex: 3, suppressMovable: true},
        {field: 'cost', flex: 2, suppressMovable: true},
        {field: 'slots', flex: 2, suppressMovable: true}
    ]);

    const defaultColDef = useMemo(() => { 
        return {
            sortable: true,
            filter: false,
            resizable: true,
            cellStyle: {textAlign: 'left'}
        };
    }, []);

    const handleOddsChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        props.onOddsUpdated(name, value);
    }

    return(
    <div>
        <div>
            <h1 className="Pad-top-and-bottom-10px">Settings</h1>
        </div>
        <div className="Config-menu-content">
            <h3>Odds</h3>
            <FormTable>
                <tbody>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="chanceToPassOnCommonTrait">Chance to pass on Common trait  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="chanceToPassOnCommonTrait"
                            id="chanceToPassOnCommonTrait"
                            min="0"
                            max="1"
                            step="0.05"
                            display="table-cell"
                            value={props.odds.chanceToPassOnCommonTrait}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="chanceToPassOnUncommonTrait">Chance to pass on Uncommon trait  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="chanceToPassOnUncommonTrait"
                            id="chanceToPassOnUncommonTrait"
                            min="0"
                            max="1"
                            step="0.05"
                            display="table-cell"
                            value={props.odds.chanceToPassOnUncommonTrait}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="chanceToPassOnRareTrait">Chance to pass on Rare trait  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="chanceToPassOnRareTrait"
                            id="chanceToPassOnRareTrait"
                            min="0"
                            max="1"
                            step="0.05"
                            display="table-cell"
                            value={props.odds.chanceToPassOnRareTrait}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="chanceOfMutation">Chance of mutation  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="chanceOfMutation"
                            id="chanceOfMutation"
                            min="0"
                            max="1"
                            step="0.05"
                            display="table-cell"
                            value={props.odds.chanceOfMutation}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="chanceToGetWrongErrandReward">Chance to get wrong errand reward  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="chanceToGetWrongErrandReward"
                            id="chanceToGetWrongErrandReward"
                            min="0"
                            max="1"
                            step="0.05"
                            display="table-cell"
                            value={props.odds.chanceToGetWrongErrandReward}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="minGearsFromErrand">Minimum gears found on errand  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="minGearsFromErrand"
                            id="minGearsFromErrand"
                            min="0"
                            max="9999"
                            step="1"
                            display="table-cell"
                            value={props.odds.minGearsFromErrand}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="maxGearsFromErrand">Maximum gears found on errand  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="maxGearsFromErrand"
                            id="maxGearsFromErrand"
                            min="0"
                            max="9999"
                            step="1"
                            display="table-cell"
                            value={props.odds.maxGearsFromErrand}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="minGearsFromGathering">Minimum gears found while gathering  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="minGearsFromGathering"
                            id="minGearsFromGathering"
                            min="0"
                            max="9999"
                            step="1"
                            display="table-cell"
                            value={props.odds.minGearsFromGathering}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="maxGearsFromGathering">Maximum gears found while gathering  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="maxGearsFromGathering"
                            id="maxGearsFromGathering"
                            min="0"
                            max="9999"
                            step="1"
                            display="table-cell"
                            value={props.odds.maxGearsFromGathering}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="chanceToFailGatheringInFields">Chance to find nothing while gathering in the fields  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="chanceToFailGatheringInFields"
                            id="chanceToFailGatheringInFields"
                            min="0"
                            max="1"
                            step="0.05"
                            display="table-cell"
                            value={props.odds.chanceToFailGatheringInFields}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="chanceToGetCompanionFromGatheringInPlaza">Chance to get companion from gathering in the plaza </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="chanceToGetCompanionFromGatheringInPlaza"
                            id="chanceToGetCompanionFromGatheringInPlaza"
                            min="0"
                            max="1"
                            step="0.05"
                            display="table-cell"
                            value={props.odds.chanceToGetCompanionFromGatheringInPlaza}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="slotsToGetChildFromGathering">Chance slots to find a child while gathering in the fields  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="slotsToGetChildFromGathering"
                            id="slotsToGetChildFromGathering"
                            min="0"
                            max="9999"
                            step="1"
                            display="table-cell"
                            value={props.odds.slotsToGetChildFromGathering}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="slotsToGetItemFromGathering">Chance slots to find an item while gathering in the fields  </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="slotsToGetItemFromGathering"
                            id="slotsToGetItemFromGathering"
                            min="0"
                            max="9999"
                            step="1"
                            display="table-cell"
                            value={props.odds.slotsToGetItemFromGathering}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                    <tr>
                        <FormTableCell>
                            <label display="table-cell" text-align="right" htmlFor="blindBagMinimumValue">Blind bag minimum value </label>
                        </FormTableCell>
                        <FormTableCell>
                            <input
                            type="number"
                            name="blindBagMinimumValue"
                            id="blindBagMinimumValue"
                            min="0"
                            max="9999"
                            step="1"
                            display="table-cell"
                            value={props.odds.blindBagMinimumValue}
                            onChange={handleOddsChange}
                            />
                        </FormTableCell>
                    </tr>
                </tbody>
            </FormTable>
            <h3>Deer Traits</h3>
            <div className="ag-theme-alpine-dark" style={{width: "80%", height: 400}}>
                <AgGridReact
                    rowData={props.allDeerTraits}
                    columnDefs={traitTableColumns}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    rowSelection="single"
                />
            </div>
            <h3>Items</h3>
            <div className="ag-theme-alpine-dark" style={{width: "80%", height: 400}}>
                <AgGridReact
                    rowData={props.items}
                    columnDefs={itemTableColumns}
                    defaultColDef={defaultColDef}
                    animateRows={true}
                    rowSelection="single"
                />
            </div>
        </div>
    </div>)
}