import React from 'react';
import DBConfig from '../DBConfig';
import Secrets from '../Secrets';
 
class DBConnector extends React.Component {

  constructor(props) {
    super(props);
    this.apiKey = Secrets.googleApiKey;
  }
  
  componentDidMount() {

    this.retrieveAllDeerTraits();
    this.retrieveListedDeerTraits();
    this.retrieveAllCompanionTraits();
    this.retrieveListedCompanionTraits();
    this.retrieveOdds();
    this.retrieveItems();
    this.retrieveEventRewards();
    this.retrieveTemplates();
    this.retrieveArtists();
    this.retrieveDeer();
    this.retrieveCompanions();
  }

  retrieveRowsFromSpreadsheet(spreadsheetId, spreadsheetTabName, onRetrievedRows) {
    const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values:batchGet?ranges=${spreadsheetTabName}&majorDimension=ROWS&key=${this.apiKey}`;

    fetch(url).then(response => response.json()).then(data => {

        if(data.error != null) {
            console.log("Received error response from Google Sheets API: " + JSON.stringify(data));
        }
        else {
            let batchRowValues = data.valueRanges[0].values;
            const rows = [];

            for (let i = 1; i < batchRowValues.length; i++) {
              let rowObject = {};
              for (let j = 0; j < batchRowValues[i].length; j++) {
                rowObject[batchRowValues[0][j]] = batchRowValues[i][j];
              }
              rows.push(rowObject);
            }

            onRetrievedRows(rows);
        }
      });
  }

  retrieveAllDeerTraits () {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.publicSpreadsheetId,
      DBConfig.allDeerTraitsSheetName,
      (rows) => {
        let allTraits = [];
        for (let i = 0; i < rows.length; i++) {
          if (rows[i]["ID"] !== undefined && rows[i]["ID"] !== "") {
            allTraits.push(
              {
                id: rows[i]["ID"],
                name: rows[i]["Name"],
                rarity: rows[i]["Rarity"],
                category: rows[i]["Category"]
              })
          }
        }

        console.log("Retrieved all deer traits from Google Sheets: " + JSON.stringify(allTraits));
        this.props.onAllDeerTraitsUpdated(allTraits);
      }
    );
  }

  retrieveListedDeerTraits () {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.siteSpreadsheetId,
      DBConfig.listedDeerTraitsSheetName,
      (rows) => {
        let listedTraits = [];
        for (let i = 0; i < rows.length; i++) {
          if (rows[i]["ID"] !== undefined && rows[i]["ID"] !== "") {
            listedTraits.push(
              {
                name: rows[i]["Name"],
                id: rows[i]["ID"],
                rarity: rows[i]["Rarity"],
                category: rows[i]["Category"]
              })
          }
        }

        console.log("Retrieved listed deer traits from Google Sheets: " + JSON.stringify(listedTraits));
        this.props.onListedDeerTraitsUpdated(listedTraits);
      }
    );
  }

  retrieveAllCompanionTraits () {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.publicSpreadsheetId,
      DBConfig.allCompanionTraitsSheetName,
      (rows) => {
        let allTraits = [];
        for (let i = 0; i < rows.length; i++) {
          if (rows[i]["ID"] !== undefined && rows[i]["ID"] !== "") {
            allTraits.push(
              {
                id: rows[i]["ID"],
                name: rows[i]["Name"],
                rarity: rows[i]["Rarity"],
                category: rows[i]["Category"]
              })
          }
        }

        console.log("Retrieved companion traits from Google Sheets: " + JSON.stringify(allTraits));
        this.props.onAllCompanionTraitsUpdated(allTraits);
      }
    );
  }

  retrieveListedCompanionTraits () {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.siteSpreadsheetId,
      DBConfig.listedCompanionTraitsSheetName,
      (rows) => {
        let listedTraits = [];
        for (let i = 0; i < rows.length; i++) {
          if (rows[i]["ID"] !== undefined && rows[i]["ID"] !== "") {
            listedTraits.push(
              {
                name: rows[i]["Name"],
                id: rows[i]["ID"],
                rarity: rows[i]["Rarity"],
                category: rows[i]["Category"]
              })
          }
        }

        console.log("Retrieved listed companion traits from Google Sheets: " + JSON.stringify(listedTraits));
        this.props.onListedCompanionTraitsUpdated(listedTraits);
      }
    );
  }

  retrieveOdds() {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.siteSpreadsheetId,
      DBConfig.oddsSheetName,
      (rows) => {
        let odds = {};
        for (let i = 0; i < rows.length; i++) {

          let name = rows[i]["Name"];
          let value = rows[i]["Value"];

          if (name !== undefined && name !== "") {
            odds[name] = value;
          }
        }

        console.log("Retrieved odds from Google Sheets: " + JSON.stringify(odds));
        this.props.onOddsUpdated(odds);
      }
    );
  }

  retrieveItems() {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.siteSpreadsheetId,
      DBConfig.itemsSheetName,
      (rows) => {
        console.log("Retrieved items from Google Sheets: " + JSON.stringify(rows));
        this.props.onItemsUpdated(rows);
      }
    );
  }

  retrieveEventRewards() {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.siteSpreadsheetId,
      DBConfig.eventRewardsSheetName,
      (rows) => {
        console.log("Retrieved event rewards from Google Sheets: " + JSON.stringify(rows));
        this.props.onEventRewardsUpdated(rows);
      }
    );
  }

  retrieveTemplates() {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.siteSpreadsheetId,
      DBConfig.templatesSheetName,
      (rows) => {
        console.log("Retrieved templates from Google Sheets: " + JSON.stringify(rows));
        this.props.onTemplatesUpdated(rows);
      }
    );
  }

  retrieveArtists() {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.siteSpreadsheetId,
      DBConfig.artistsSheetName,
      (rows) => {
        console.log("Retrieved artists from Google Sheets: " + JSON.stringify(rows));
        this.props.onArtistsUpdated(rows);
      }
    );
  }

  retrieveDeer() {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.publicSpreadsheetId,
      DBConfig.deerSheetName,
      (rows) => {
          // The info for each deer is split across multiple rows.
          // Consolidate the rows so we have one object for each deer.

          let deerList = [];
          let currentDeer = undefined;
          for (let i = 0; i < rows.length; i++) {

            const deerId = rows[i]["ID"];
            const deerName = rows[i]["Name"];
            const traitId = rows[i]["Trait ID"];

            // Start new deer.
            if (deerId !== undefined && deerId !== "") {

              if (currentDeer !== undefined) {
                deerList.push(currentDeer);
              }

              currentDeer = {id: deerId, name: "", traitIds: []};

              if (deerName !== undefined && deerName !== "") {
                currentDeer.name = deerName;
              }

              if (traitId !== undefined && traitId !== "") {
                currentDeer.traitIds.push(traitId);
              }
            }
            // Add Trait IDs to current deer.
            else if (traitId !== undefined && traitId !== "") {
              currentDeer.traitIds.push(traitId);
            }
          }

          console.log("Retrieved deer from Google Sheets: " + JSON.stringify(deerList));
          this.props.onDeerUpdated(deerList);
      }
    );
  }

  retrieveCompanions() {

    this.retrieveRowsFromSpreadsheet(
      DBConfig.publicSpreadsheetId,
      DBConfig.companionsSheetName,
      (rows) => {
          // The info for each companion is split across multiple rows.
          // Consolidate the rows so we have one object for each companion.

          let companions = [];
          let currentCompanion = undefined;
          for (let i = 0; i < rows.length; i++) {
            const companionId = rows[i]["ID"];
            const companionName = rows[i]["Name"];
            const species = rows[i]["Species"];
            const tailLength = rows[i]["Tail Length"];
            const traitId = rows[i]["Trait ID"];

            // Start new companion.
            if (companionId !== undefined && companionId !== "") {

              if (currentCompanion !== undefined) {
                companions.push(currentCompanion);
              }

              currentCompanion = {id: companionId, name: "", species: "", traitIds: []};

              if (companionName !== undefined && companionName !== "") {
                currentCompanion.name = companionName;
              }

              if (species !== undefined && species !== "") {
                currentCompanion.species = species;
              }

              if (tailLength !== undefined && tailLength !== "") {
                currentCompanion.tailLength = tailLength;
              }

              if (traitId !== undefined && traitId !== "") {
                currentCompanion.traitIds.push(traitId);
              }
            }
            // Add Trait IDs to current deer.
            else if (traitId !== undefined && traitId !== "") {
              currentCompanion.traitIds.push(traitId);
            }
          }

          console.log("Retrieved companions from Google Sheets: " + JSON.stringify(companions));
          this.props.onCompanionsUpdated(companions);
      }
    );
  }

  render() {
    return <div/>;
  }
}

export default DBConnector;