import './../styles/App.css';
import React from 'react';
import {useState, useEffect} from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const FriendInfoPanelContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
`;

const FriendInfoPanel = styled.div`
    min-width: 300px;
    background-color: #282c34;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px;
    padding: 10px;
`;

const spacerImageColumnName = "Spacer Image";

// Template tokens
const artistSignatureToken = "{artistSignature}";
const companionNameToken = "{companionName}";
const companionIdToken = "{companionId}";
const companionGenderToken = "{companionGender}";
const companionPairingToken = "{companionPairing}";
const deerNameToken = "{deerName}";
const deerIdToken = "{deerId}";
const deerGenderToken = "{deerGender}";
const deerRarityToken = "{deerRarity}";
const imageToken = "{image}";
const litterNameToken = "{litterName}";
const litterNumberToken = "{litterNumber}";
const ownerNameToken = "{ownerName}";
const ownerLinkToken = "{ownerLink}";
const formattedDeerListStartToken = "{formattedDeerList}";
const formattedDeerListEndToken = "{/formattedDeerList}";
const formattedCompanionListStartToken = "{formattedCompanionList}";
const formattedCompanionListEndToken = "{/formattedCompanionList}";
const formattedMergeListStartToken = "{formattedMergeList}";
const formattedMergeListEndToken = "{/formattedMergeList}";

// BBCode tokens
const leftStartToken = "[left]";
const leftEndToken = "[/left]";
const rightStartToken = "[right]";
const rightEndToken = "[/right]";

export default function TemplatesMenu(props) {

    const [selectedTemplate, setSelectedTemplate] = useState();
    const [selectedArtist, setSelectedArtist] = useState();
    const [result, setResult] = useState("");

    const [inputs, setInputs] = useState({
        image: "",
        deerName: "",
        deerId: "",
        litterNumber: "",
        litterName: "",
        companionName: "",
        ownerName: "",
        ownerLink: ""
    });

    const [numberOfDeer, setNumberOfDeer] = useState(1);
    const [numberOfCompanions, setNumberOfCompanions] = useState(1);
    const [numberOfMerges, setNumberOfMerges] = useState(1);
    const [deerListInputs, setDeerListInputs] = useState([{}]);
    const [companionListInputs, setCompanionListInputs] = useState([{}]);
    const [mergeListInputs, setMergeListInputs] = useState([{}]);

    useEffect(()=>{
        // When artist list changes, set all dropdowns to the first value
        setSelectedArtist(props.artists[0]);

        let event =
        {
            target:
            {
                value: JSON.stringify(props.artists[0])
            }
        }

        for (let i = 0; i < numberOfMerges; i++) {
            handleMergeArtistChanged(i, event);
        }
    }, [props.artists]);

    useEffect(()=>{
        setSelectedTemplate(props.templates[0]);
    }, [props.templates]);

    const onInputsChanged = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setInputs(values => ({...values, [name]: value}));
    }

    const onNumberOfDeerChanged = (event) => {
        let newNum = event.target.value;
        setNumberOfDeer(newNum);

        if (newNum > deerListInputs.length) {
            let numberOfDeerToAdd = newNum - deerListInputs.length;

            const newInputs = deerListInputs.slice();

            for (let i = 0; i < numberOfDeerToAdd; i++) {
                newInputs.push({});
            }

            setDeerListInputs(newInputs);
        }
    }

    const onNumberOfCompanionsChanged = (event) => {
        let newNum = event.target.value;

        setNumberOfCompanions(newNum);

        if (newNum > companionListInputs.length) {
            let numberOfCompanionsToAdd = newNum - companionListInputs.length;

            const newInputs = companionListInputs.slice();

            for (let i = 0; i < numberOfCompanionsToAdd; i++) {
                newInputs.push({});
            }

            setCompanionListInputs(newInputs);
        }
    }

    const onNumberOfMergesChanged = (event) => {
        let newNum = event.target.value;

        setNumberOfMerges(newNum);

        if (newNum > mergeListInputs.length) {
            let numberOfMergesToAdd = newNum - mergeListInputs.length;

            const newInputs = mergeListInputs.slice();

            for (let i = 0; i < numberOfMergesToAdd; i++) {
                newInputs.push({mergeArtist: props.artists[0]});
            }

            console.log("New inputs: " + JSON.stringify(newInputs));

            setMergeListInputs(newInputs);
        }
    }

    const onDeerListInputsChanged = (index, event) => {
        const propertyName = event.target.name;
        const value = event.target.value;

        let newInputs = deerListInputs.slice();
        if (newInputs[index] === undefined) {
            newInputs[index] = {[propertyName]: value}
        }
        else {
            newInputs[index][propertyName] = value;
        }

        setDeerListInputs(newInputs);
    }

    const onCompanionListInputsChanged = (index, event) => {
        const propertyName = event.target.name;
        const value = event.target.value;

        let newInputs = companionListInputs.slice();
        if (newInputs[index] === undefined) {
            newInputs[index] = {[propertyName]: value}
        }
        else {
            newInputs[index][propertyName] = value;
        }

        setCompanionListInputs(newInputs);
    }

    const onMergeListInputsChanged = (index, event) => {
        const propertyName = event.target.name;
        const value = event.target.value;

        let newInputs = mergeListInputs.slice();
        if (newInputs[index] === undefined) {
            newInputs[index] = {[propertyName]: value}
        }
        else {
            newInputs[index][propertyName] = value;
        }

        setMergeListInputs(newInputs);
    };

    const handleSelectedTemplateChanged = (e) => {
        let newTemplate = JSON.parse(e.target.value);
        setSelectedTemplate(newTemplate);
    };

    const handleSelectedArtistChanged = (e) => {
        let newArtist = JSON.parse(e.target.value);
        setSelectedArtist(newArtist);
    };

    const handleMergeArtistChanged = (index, e) => {
        let newArtist = JSON.parse(e.target.value);
        onMergeListInputsChanged(index, {target: {name: "mergeArtist", value: newArtist}});
    };

    // Takes a string containing a start and end token.
    // Separates into the portion before the start token, the portion between the tokens, and the portion after the end token.
    const separateStringOnStartAndEndToken = (fullString, startToken, endToken) => {
        // Using split because I don't want to figure out regex.
        const splitOnStartToken = fullString.split(startToken);
        const beforeStartToken = splitOnStartToken[0];
        const afterStartToken = splitOnStartToken[1];

        const splitOnEndToken = afterStartToken.split(endToken);
        const beforeEndToken = splitOnEndToken[0];
        const afterEndToken = splitOnEndToken[1];

        const betweenTokens = beforeEndToken;

        return [beforeStartToken, betweenTokens, afterEndToken];
    };

    const generatePost = () => {
        var resultString = "";
        resultString = selectedTemplate?.Template ? selectedTemplate.Template : "No template selected";

        var spacerImage = selectedTemplate[spacerImageColumnName];
        console.log("Spacer image is " + spacerImage);

        if (resultString.includes(formattedDeerListStartToken)) {
            // Extract portion betwen formattedDeerListStartToken and formattedDeerListEndToken
            const beforeWithinAndAfterToken = separateStringOnStartAndEndToken(resultString, formattedDeerListStartToken, formattedDeerListEndToken);
            const beforeToken = beforeWithinAndAfterToken[0];
            const withinToken = beforeWithinAndAfterToken[1];
            const afterToken = beforeWithinAndAfterToken[2];

            let deerInfoStrings = [];
            for(let i = 0; i < numberOfDeer; i++) {
                if(deerListInputs[i] !== undefined) {
                    let deerInfo = withinToken;

                    deerInfo = deerInfo.replaceAll(deerIdToken, valueOrEmptyString(deerListInputs[i].id));
                    deerInfo = deerInfo.replaceAll(imageToken, valueOrEmptyString(deerListInputs[i].image));
                    deerInfo = deerInfo.replaceAll(deerNameToken, valueOrEmptyString(deerListInputs[i].name));
                    deerInfo = deerInfo.replaceAll(deerGenderToken, valueOrEmptyString(deerListInputs[i].gender));
                    deerInfo = deerInfo.replaceAll(deerRarityToken, valueOrEmptyString(deerListInputs[i].rarity));
                    deerInfo = deerInfo.replaceAll(ownerNameToken, valueOrEmptyString(deerListInputs[i].ownerName));
                    deerInfo = deerInfo.replaceAll(ownerLinkToken, valueOrEmptyString(deerListInputs[i].ownerLink));
                    deerInfoStrings.push(deerInfo);
                }
            }

            const formattedDeerInfo = formatListOfItems(spacerImage, deerInfoStrings);

            resultString = beforeToken + formattedDeerInfo + afterToken;
        }

        if (resultString.includes(formattedCompanionListStartToken)) {
            // Extract portion betwen formattedCompanionListStartToken and formattedCompanionListEndToken
            const beforeWithinAndAfterToken = separateStringOnStartAndEndToken(resultString, formattedCompanionListStartToken, formattedCompanionListEndToken);
            const beforeToken = beforeWithinAndAfterToken[0];
            const withinToken = beforeWithinAndAfterToken[1];
            const afterToken = beforeWithinAndAfterToken[2];

            let companionInfoStrings = [];
            for(let i = 0; i < numberOfCompanions; i++) {
                if(companionListInputs[i] !== undefined) {
                    let companionInfo = withinToken;

                    companionInfo = companionInfo.replaceAll(companionIdToken, valueOrEmptyString(companionListInputs[i].id));
                    companionInfo = companionInfo.replaceAll(imageToken, valueOrEmptyString(companionListInputs[i].image));
                    companionInfo = companionInfo.replaceAll(companionNameToken, valueOrEmptyString(companionListInputs[i].name));
                    companionInfo = companionInfo.replaceAll(companionGenderToken, valueOrEmptyString(companionListInputs[i].gender));
                    companionInfo = companionInfo.replaceAll(companionPairingToken, valueOrEmptyString(companionListInputs[i].pairing));
                    companionInfo = companionInfo.replaceAll(ownerNameToken, valueOrEmptyString(companionListInputs[i].ownerName));
                    companionInfo = companionInfo.replaceAll(ownerLinkToken, valueOrEmptyString(companionListInputs[i].ownerLink));
                    companionInfoStrings.push(companionInfo);
                }
            }

            const formattedCompanionInfo = formatListOfItems(spacerImage, companionInfoStrings);

            resultString = beforeToken + formattedCompanionInfo + afterToken;
        }

        if (resultString.includes(formattedMergeListStartToken)) {
            // Extract portion betwen formattedMergeListStartToken and formattedMergeListEndToken
            const beforeWithinAndAfterToken = separateStringOnStartAndEndToken(resultString, formattedMergeListStartToken, formattedMergeListEndToken);
            const beforeToken = beforeWithinAndAfterToken[0];
            const withinToken = beforeWithinAndAfterToken[1];
            const afterToken = beforeWithinAndAfterToken[2];

            let mergeInfoStrings = [];
            for(let i = 0; i < numberOfMerges; i++) {
                if(mergeListInputs[i] !== undefined) {
                    let mergeInfo = withinToken;

                    mergeInfo = mergeInfo.replaceAll(imageToken, valueOrEmptyString(mergeListInputs[i].image));
                    mergeInfo = mergeInfo.replaceAll(deerNameToken, valueOrEmptyString(mergeListInputs[i].deerName));
                    mergeInfo = mergeInfo.replaceAll(companionNameToken, valueOrEmptyString(mergeListInputs[i].companionName));
                    mergeInfo = mergeInfo.replaceAll(ownerNameToken, valueOrEmptyString(mergeListInputs[i].ownerName));
                    mergeInfo = mergeInfo.replaceAll(ownerLinkToken, valueOrEmptyString(mergeListInputs[i].ownerLink));
                    mergeInfo = mergeInfo.replaceAll(artistSignatureToken, valueOrEmptyString(mergeListInputs[i].mergeArtist.Signature));
                    mergeInfoStrings.push(mergeInfo);
                }
            }

            const formattedMergeInfo = formatListOfItems(spacerImage, mergeInfoStrings);

            resultString = beforeToken + formattedMergeInfo + afterToken;
        }

        resultString = resultString.replaceAll(imageToken, inputs.image);
        resultString = resultString.replaceAll(deerNameToken, inputs.deerName);
        resultString = resultString.replaceAll(deerIdToken, inputs.deerId);
        resultString = resultString.replaceAll(litterNameToken, inputs.litterName);
        resultString = resultString.replaceAll(litterNumberToken, inputs.litterNumber);
        resultString = resultString.replaceAll(companionNameToken, inputs.companionName);
        resultString = resultString.replaceAll(companionIdToken, inputs.companionId);
        resultString = resultString.replaceAll(companionGenderToken, inputs.companionGender);
        resultString = resultString.replaceAll(companionPairingToken, inputs.companionPairing);
        resultString = resultString.replaceAll(ownerNameToken, inputs.ownerName);
        resultString = resultString.replaceAll(ownerLinkToken, inputs.ownerLink);
        resultString = resultString.replaceAll(artistSignatureToken, selectedArtist.Signature);

        setResult(resultString);
    };

    // Takes a list of items and formats them using 'left' and 'right' BBCode tags and a spacing image.
    const formatListOfItems = (spacerImage, items) => {

        if (items.length === 0) {
            return "";
        }

        // Make as many rows of 3 as possible,
        // but if we are left with a row of 1 then replace it with 2 rows of 2.
        var numRowsOfOne = 0;
        var numRowsOfTwo = 0;
        var numRowsOfThree = Math.floor(items.length / 3);
        const remainder = items.length % 3;

        if (remainder === 1) {
            if (numRowsOfThree > 0) {
                numRowsOfThree--;
                numRowsOfTwo = 2;
            }
            else {
                numRowsOfOne = 1;
            }
        }
        else if (remainder === 2) {
            numRowsOfTwo = 1;
        }

        // Construct the formatted string.
        var result = "";
        var index = 0;

        var leftItems = [];
        var rightItems = [];
        var centerItems = [];

        // Add rows of 3.
        // If there are multiple consecutive rows of 2,
        // then all left items are combined into a single left tag and all right items are combined into a single right tag.
        if (numRowsOfThree > 0) {
            for(let i = 0; i < numRowsOfThree; i++) {
                leftItems.push(items[index]);
                index++;
                centerItems.push(items[index]);
                index++;
                rightItems.push(items[index]);
                index++;
            }

            // Generate left column.
            result += leftStartToken;
            for(let i = 0; i < numRowsOfThree; i++) {
                result += leftItems[i];
            }
            result += leftEndToken;
    
            // Generate right column.
            result += rightStartToken;
            for(let i = 0; i < numRowsOfThree; i++) {
                result += rightItems[i];
            }
            result += rightEndToken;

            // Generate center column.
            for(let i = 0; i < numRowsOfThree; i++) {
                result += centerItems[i];
            }
        }

        // Add rows of 2.
        // If there are multiple consecutive rows of 2,
        // then all left items are combined into a single left tag and all right items are combined into a single right tag.
        if (numRowsOfTwo > 0) {
           leftItems = [];
           rightItems = [];

            for(let i = 0; i < numRowsOfTwo; i++) {
                leftItems.push(items[index]);
                index++;
                rightItems.push(items[index]);
                index++;
            }
    
            // Generate left column.
            result += leftStartToken + spacerImage + leftEndToken;
            result += leftStartToken;
            for(let i = 0; i < numRowsOfTwo; i++) {
                result += leftItems[i];
            }
            result += leftEndToken;
    
            // Generate right column.
            result += rightStartToken + spacerImage + rightEndToken;
            result += rightStartToken;
            for(let i = 0; i < numRowsOfTwo; i++) {
                result += rightItems[i];
            }
            result += rightEndToken;
        }

        // Add rows of 1.
        for(let i = 0; i < numRowsOfOne; i++) {
            const centerItem = items[index];
            index++;
            result += centerItem;
        }

        return result;
    };

    const valueOrEmptyString = (value) => {
        if(value === undefined) {
            return "";
        }
        else {
            return value;
        }
    };

    const shouldShowGlobalField = (fieldToken) => {

        var template = selectedTemplate?.Template;

        if (template === null || template === undefined) {
            return false;
        }

        if (!template.includes(fieldToken)) {
            return false;
        }

        if (template.includes(formattedDeerListStartToken)) {
            const beforeWithinAndAfterToken = separateStringOnStartAndEndToken(template, formattedDeerListStartToken, formattedDeerListEndToken);

            if (beforeWithinAndAfterToken[1].includes(fieldToken)) {
                return false;
            }
        }

        if (template.includes(formattedCompanionListStartToken)) {
            const beforeWithinAndAfterToken = separateStringOnStartAndEndToken(template, formattedCompanionListStartToken, formattedCompanionListEndToken);

            if (beforeWithinAndAfterToken[1].includes(fieldToken)) {
                return false;
            }
        }

        if (template.includes(formattedMergeListStartToken)) {
            const beforeWithinAndAfterToken = separateStringOnStartAndEndToken(template, formattedMergeListStartToken, formattedMergeListEndToken);

            if (beforeWithinAndAfterToken[1].includes(fieldToken)) {
                return false;
            }
        }

        return true;
    };

    const renderDeerInfoPanels = () => {

        let deerNumberRange = [];
        for(let i = 0; i < numberOfDeer; i++) {
            deerNumberRange.push(i);
        }

        return deerNumberRange.map(number => 
            <FriendInfoPanel key={number}>
                <h2>Deer {number + 1}</h2>
                <Form>
                    <Row style={{justifyContent: "center"}}>
                        <Col style={{justifyContent: "center"}}>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="deerNameLabel">
                                Name
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="name"
                                    id={number}
                                    value={valueOrEmptyString(deerListInputs[number].name)}
                                    onChange={e => onDeerListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="deerNumberLabel">
                                Number
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="id"
                                    id={number}
                                    value={valueOrEmptyString(deerListInputs[number].id)}
                                    onChange={e => onDeerListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="deerImageLabel">
                                Image
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="image"
                                    id={number}
                                    value={valueOrEmptyString(deerListInputs[number].image)}
                                    onChange={e => onDeerListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="deerGenderLabel">
                                Gender
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="gender"
                                    id={number}
                                    value={valueOrEmptyString(deerListInputs[number].gender)}
                                    onChange={e => onDeerListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="deerRarityLabel">
                                Rarity
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="rarity"
                                    id={number}
                                    value={valueOrEmptyString(deerListInputs[number].rarity)}
                                    onChange={e => onDeerListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="deerOwnerNameLabel">
                                Owner Name
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="ownerName"
                                    id={number}
                                    value={valueOrEmptyString(deerListInputs[number].ownerName)}
                                    onChange={e => onDeerListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="deerOwnerLinkLabel">
                                Owner Link
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="ownerLink"
                                    id={number}
                                    value={valueOrEmptyString(deerListInputs[number].ownerLink)}
                                    onChange={e => onDeerListInputsChanged(number, e)}/>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            </FriendInfoPanel>);
    }

    const renderCompanionInfoPanels = () => {

        let companionNumberRange = [];
        for(let i = 0; i < numberOfCompanions; i++) {
            companionNumberRange.push(i);
        }

        return companionNumberRange.map(number => 
            <FriendInfoPanel key={number}>
                <h2>Companion {number + 1}</h2>
                <Form>
                    <Row style={{justifyContent: "center"}}>
                        <Col style={{justifyContent: "center"}}>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="companionNameLabel">
                                Name
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="name"
                                    id={number}
                                    value={valueOrEmptyString(companionListInputs[number].name)}
                                    onChange={e => onCompanionListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="companionNumberLabel">
                                Number
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="id"
                                    id={number}
                                    value={valueOrEmptyString(companionListInputs[number].id)}
                                    onChange={e => onCompanionListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="companionImageLabel">
                                Image
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="image"
                                    id={number}
                                    value={valueOrEmptyString(companionListInputs[number].image)}
                                    onChange={e => onCompanionListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="companionGenderLabel">
                                Gender
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="gender"
                                    id={number}
                                    value={valueOrEmptyString(companionListInputs[number].gender)}
                                    onChange={e => onCompanionListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="companionPairingLabel">
                                Pairing
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="pairing"
                                    id={number}
                                    value={valueOrEmptyString(companionListInputs[number].pairing)}
                                    onChange={e => onCompanionListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="companionOwnerNameLabel">
                                Owner Name
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="ownerName"
                                    id={number}
                                    value={valueOrEmptyString(companionListInputs[number].ownerName)}
                                    onChange={e => onCompanionListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="companionOwnerLinkLabel">
                                Owner Link
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="ownerLink"
                                    id={number}
                                    value={valueOrEmptyString(companionListInputs[number].ownerLink)}
                                    onChange={e => onCompanionListInputsChanged(number, e)}/>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            </FriendInfoPanel>);
    }

    const renderMergeInfoPanels = () => {

        let mergeNumberRange = [];
        for(let i = 0; i < numberOfMerges; i++) {
            mergeNumberRange.push(i);
        }

        return mergeNumberRange.map(number => 
            <FriendInfoPanel key={number}>
                <h2>Merge {number + 1}</h2>
                <Form>
                    <Row style={{justifyContent: "center"}}>
                        <Col style={{justifyContent: "center"}}>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="mergeImageLabel">
                                Image
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="image"
                                    id={number}
                                    value={valueOrEmptyString(mergeListInputs[number].image)}
                                    onChange={e => onMergeListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="mergeDeerNameLabel">
                                Deer Name
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="deerName"
                                    id={number}
                                    value={valueOrEmptyString(mergeListInputs[number].deerName)}
                                    onChange={e => onMergeListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="mergeCompanionNameLabel">
                                Companion Name
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="companionName"
                                    id={number}
                                    value={valueOrEmptyString(mergeListInputs[number].companionName)}
                                    onChange={e => onMergeListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="mergeOwnerNameLabel">
                                Owner Name
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="ownerName"
                                    id={number}
                                    value={valueOrEmptyString(mergeListInputs[number].ownerName)}
                                    onChange={e => onMergeListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="mergeOwnerLinkLabel">
                                Owner Link
                                </InputGroup.Text>
                                <Form.Control
                                    style={{maxWidth: "350px"}}
                                    variant="dark"
                                    type="text"
                                    name="ownerLink"
                                    id={number}
                                    value={valueOrEmptyString(mergeListInputs[number].ownerLink)}
                                    onChange={e => onMergeListInputsChanged(number, e)}/>
                            </InputGroup>
                            <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                <InputGroup.Text id="mergeArtistLabel">
                                Artist
                                </InputGroup.Text>
                                <Form.Select 
                                    name="mergeArtist"
                                    id={number}
                                    onChange={e => handleMergeArtistChanged(number, e)}>
                                    { props.artists.map((opt, idx) => 
                                        <option 
                                            key={idx}
                                            value={JSON.stringify(opt)}>
                                        {opt.Name}
                                        </option>
                                    )}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                </Form>
            </FriendInfoPanel>);
    }

    return(
    <div>
        <div>
            <h1 className="Pad-top-and-bottom-10px">Templates</h1>
        </div>
        <div className="Templates-menu-content">
            <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                <Form>
                    <div style={{margin: "10px"}}>
                        <InputGroup size="lg">
                            <InputGroup.Text id="selectedTemplateLabel">
                            Template
                            </InputGroup.Text>
                            <Form.Select 
                                style={{maxWidth: "400px"}}
                                id="selectedTemplate"
                                onChange={handleSelectedTemplateChanged}>
                                { props.templates.map((opt, idx) => 
                                    <option 
                                        key={idx}
                                        value={JSON.stringify(opt)}>
                                    {opt.Name}
                                    </option>
                                )}
                            </Form.Select>
                        </InputGroup>
                    </div>
                    <Row style={{justifyContent: "center"}}>
                        <Col style={{justifyContent: "center"}}>
                            {/* Input forms are generated dynamically based on the tokens contained in the template. */}
                            <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems:"center", justifyContent: "center"}}>
                                {
                                    shouldShowGlobalField(litterNameToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="litterNameLabel">
                                        Litter Name
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="litterName"
                                            value={inputs.litterName}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(litterNumberToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="litterNumberLabel">
                                        Litter Number
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="litterNumber"
                                            value={inputs.litterNumber}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(imageToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="imageLabel">
                                        Image
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="image"
                                            value={inputs.image}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(deerNameToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="deerNameLabel">
                                        Deer Name
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="deerName"
                                            value={inputs.deerName}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(deerIdToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="deerIdLabel">
                                        Deer ID
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="deerId"
                                            value={inputs.deerId}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(companionNameToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="companionNameLabel">
                                        Companion Name
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="companionName"
                                            value={inputs.companionName}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(companionIdToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="companionIdLabel">
                                        Companion ID
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="companionId"
                                            value={inputs.companionId}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(companionGenderToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="companionGenderLabel">
                                        Companion Gender
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="companionGender"
                                            value={inputs.companionGender}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(companionPairingToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="companionPairingLabel">
                                        Companion Pairing
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="companionPairing"
                                            value={inputs.companionPairing}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(ownerNameToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="ownerNameLabel">
                                        Owner Name
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="ownerName"
                                            value={inputs.ownerName}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(ownerLinkToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="ownerLinkLabel">
                                        Owner Link
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"
                                            type="text"
                                            name="ownerLink"
                                            value={inputs.ownerLink}
                                            onChange={onInputsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    shouldShowGlobalField(artistSignatureToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="selectedArtistLabel">
                                        Artist
                                        </InputGroup.Text>
                                        <Form.Select 
                                            id="selectedArtist"
                                            onChange={handleSelectedArtistChanged}>
                                            { props.artists.map((opt, idx) => 
                                                <option 
                                                    key={idx}
                                                    value={JSON.stringify(opt)}>
                                                {opt.Name}
                                                </option>
                                            )}
                                        </Form.Select>
                                    </InputGroup>
                                }
                                {
                                    selectedTemplate?.Template?.includes(formattedDeerListStartToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="numberOfDeerLabel">
                                        Number of Deer
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"                   
                                            type="number" 
                                            min="1"
                                            step="1"
                                            name="numberOfDeer"
                                            value={numberOfDeer}
                                            onChange={onNumberOfDeerChanged}/>
                                    </InputGroup>
                                }
                                {
                                    selectedTemplate?.Template?.includes(formattedCompanionListStartToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="numberOfCompanionsLabel">
                                        Number of Companions
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"                   
                                            type="number" 
                                            min="1"
                                            step="1"
                                            name="numberOfCompanions"
                                            value={numberOfCompanions}
                                            onChange={onNumberOfCompanionsChanged}/>
                                    </InputGroup>
                                }
                                {
                                    selectedTemplate?.Template?.includes(formattedMergeListStartToken) &&
                                    <InputGroup className="py-2" style={{justifyContent: "center"}}>
                                        <InputGroup.Text id="numberOfMergesLabel">
                                        Number of Merges
                                        </InputGroup.Text>
                                        <Form.Control
                                            variant="dark"                   
                                            type="number" 
                                            min="1"
                                            step="1"
                                            name="numberOfMerges"
                                            value={numberOfMerges}
                                            onChange={onNumberOfMergesChanged}/>
                                    </InputGroup>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form>
                {
                    selectedTemplate?.Template?.includes(formattedDeerListStartToken) &&
                    <FriendInfoPanelContainer>
                        {renderDeerInfoPanels()}
                    </FriendInfoPanelContainer>
                }
                {
                    selectedTemplate?.Template?.includes(formattedCompanionListStartToken) &&
                    <FriendInfoPanelContainer>
                        {renderCompanionInfoPanels()}
                    </FriendInfoPanelContainer>
                }
                                {
                    selectedTemplate?.Template?.includes(formattedMergeListStartToken) &&
                    <FriendInfoPanelContainer>
                        {renderMergeInfoPanels()}
                    </FriendInfoPanelContainer>
                }
            </div>
            <Button
                variant="success"
                size="lg"
                style={{width: "20%", minWidth:"150px", paddingBottom: "10px", marginTop: "10px"}}
                onClick={generatePost}>
                Generate
            </Button>
            <h3 style={{paddingBottom: "10px", paddingTop: "20px"}}>Result</h3>
            <Form.Control 
                as="textarea"
                readOnly
                rows={20} 
                style={{width:"90%", maxWidth: "900px"}}
                value={result}/>
        </div>
    </div>)
}