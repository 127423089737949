import './../styles/App.css';
import React from 'react';
import {useState} from 'react';
import {getCurrentTimestamp, getRandomFromArray} from '../Utils.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const emptyString = "";

const blindBagColumnName = "Blind Bag";

const critItemName = "CRIT";
const critColumnName = "crit";

export default function BlindBagMenu(props) {

    const [numberOfBags, setNumberOfBags] = useState(1);

    const [outcome, setOutcome] = useState("");

    const onNumberOfBagsChanged = (event) => {
        setNumberOfBags(event.target.value);
    }

    const openBags = () => {

        let blindBagItems = props.items.filter(item => item[blindBagColumnName] !== undefined && item[blindBagColumnName] !== emptyString);

        let nonCritItems = blindBagItems.filter(item => item[critColumnName] === undefined || item[critColumnName] === emptyString);

        let critItems = blindBagItems.filter(item => item[critColumnName] !== undefined && item[critColumnName] !== emptyString);

        let nonCritItemPool = [];
        nonCritItems.forEach(item => {
            for(let i = 0; i < item.slots; i++) {
                nonCritItemPool.push(item);
            }
        });

        let critItemPool = [];
        critItems.forEach(item => {
            for(let i = 0; i < item.slots; i++) {
                critItemPool.push(item);
            }
        });

        let totalItems = {};
        let itemsForEachBag = [];
        let totalValue = 0;

        // Select items for each bag
        for(let i = 0; i < numberOfBags; i++) {

            let bagValue = 0;

            let itemsForBag = {};

            while(bagValue < parseInt(props.odds.blindBagMinimumValue)) {

                let selectedItem = getRandomFromArray(nonCritItemPool);
    
                // If hit a crit, then select from crit item pool
                if(selectedItem.name === critItemName) {

                    selectedItem = getRandomFromArray(critItemPool);
                }
                
                if(itemsForBag[selectedItem.name] !== undefined) {
                    itemsForBag[selectedItem.name] = itemsForBag[selectedItem.name] + 1;
                }
                else {
                    itemsForBag[selectedItem.name] = 1;
                }

                if(totalItems[selectedItem.name] !== undefined) {
                    totalItems[selectedItem.name] = totalItems[selectedItem.name] + 1;
                }
                else {
                    totalItems[selectedItem.name] = 1;
                }

                bagValue += parseInt(selectedItem.cost);
            }

            itemsForEachBag[i] = itemsForBag;

            totalValue += bagValue;

            console.log("Bag " + (i + 1) + " value: " + bagValue);
        }
        console.log("Total value: " + totalValue);

        // Create outcome string
        let outcomeString = getCurrentTimestamp() + "\n";

        // List total items gained
        let totalItemsString = "Total items: ";

        for (let [key, value] of Object.entries(totalItems)) {
            totalItemsString = totalItemsString + " " + value + "x " + key + ",";
        }

        totalItemsString = totalItemsString.slice(0, totalItemsString.length - 1); // Remove trailing comma
        totalItemsString = totalItemsString + "\n";
        outcomeString = outcomeString + totalItemsString;

        // List items gained from each bag
        for (let i = 0; i < itemsForEachBag.length; i++) {

            let bagString = "Blind Bag " + (i + 1) + "/" + numberOfBags + ":";

            for (let [key, value] of Object.entries(itemsForEachBag[i])) {
                bagString = bagString + " " + value + "x " + key + ",";
            }

            bagString = bagString.slice(0, bagString.length - 1); // Remove trailing comma
            bagString = bagString + "\n";
            outcomeString = outcomeString + bagString;
        }

        setOutcome(outcomeString);
    };

    return(
    <div>
        <div>
            <h1 className="Pad-top-and-bottom-10px">Blind Bags</h1>
        </div>
        <div className="Blind-bag-menu-content">
            <Form>
                <InputGroup style={{maxWidth: "250px"}}>
                    <InputGroup.Text id="numberOfBagsLabel">
                    Number of bags
                    </InputGroup.Text>
                    <Form.Control
                        variant="dark"                   
                        type="number" 
                        min="1"
                        name="numberOfBags"
                        value={numberOfBags}
                        onChange={onNumberOfBagsChanged}/>
                </InputGroup>
            </Form>
            <Button
                variant="success"
                size="lg"
                style={{width: "10%", minWidth:"100px", paddingBottom: "10px", marginTop: "30px"}}
                onClick={openBags}>
                Go
            </Button>
            <h3 style={{paddingBottom: "10px", paddingTop: "20px"}}>Result</h3>
            <Form.Control 
                as="textarea"
                readOnly
                rows={20} 
                style={{width:"90%", maxWidth: "900px"}}
                value={outcome}/>
        </div>
    </div>)
}