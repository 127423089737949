export function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

export function getRandomFromArray(array) {
    return array[Math.floor(Math.random() * array.length)];
}

export function getCurrentTimestamp() {
    return new Date().toLocaleTimeString("en-US", {timeZone: "America/Chicago"});
}